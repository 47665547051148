<template>
    <button class="nav-link btn btn-link px-1" @click="show()" v-popover="popoverSettings">
        <i class="bi bi-question-lg aftoolbar-helpurl" style="cursor: pointer !important;" :title='$t("Help & Documentation links")'></i>
    </button>

    <ODialog ref="dialogRef" v-model:show="showDialog" disableResize size="lg" :title='$t("New")' :disableMovable="false" disableFade :target="targetEl">
        <div class="o365-dialog-body d-flex flex-column">
            <OUploadDrop class="d-flex flex-column flex-grow-1 w-100" v-if="!submitted" hideProgressBar :dataObject="dsAttachments" multiple="multiple" :beforeUpload="() => beforeUpload()">
                <div class="mt-1">
                    <label>{{ $t("Title") }}</label>
                    <input class="form-control form-control-sm" v-model="dsWorkflow.current.Title"/>
                </div>
                <div class="mt-1">
                    <label>{{ $t("Description") }}</label>
                    <OTextArea 
                        v-model="dsWorkflow.current.Description"
                        class="form-control form-control-sm"
                        autoGrow
                        rows="4"
                        noResize/>
                </div>
                <div class="mt-3">
                    <div class="lh-lg pb-2 d-flex align-items-center gap-2 justify-content-between">
                        <div>
                            {{ $t("Drag and drop images here, or ") }}
                            <OFileUploadButton :dataObject="dsAttachments" hideProgressBar :text="$t('Choose Files...')" :beforeUpload="() => beforeUpload()" btnClass="btn btn-outline-primary btn-sm align-bottom"/>
                        </div>
                    </div>        
                    <div>
                        <OAttachments :dataObject="dsAttachments" showFileNames></OAttachments>
                    </div>
                </div>
                <div class="mt-auto">
                    <div class="my-3 border"></div>
                    <div class="d-flex" >
                        <div class="ms-auto">
                            <button class="btn btn-sm btn-primary ms-1" @click="submitWorkflow">
                                <span class="me-1">{{ $t("Submit") }}</span>
                                <div class="spinner-border spinner-border-sm" role="status" v-if="submitting">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </OUploadDrop>
            <div v-else class="d-flex justify-content-center" style="height: 100px;">
                <div>
                    <div class="text-center">
                        <i class="bi bi-check-circle text-success" style="font-size: 40px;"></i>
                    </div>
                    <div class="text-center fw-bold">
                        {{ $t("Workflow submitted") }}
                    </div>
                    <div class="text-center">
                        <small><a :href="`/scope-workflow?ID=${dsWorkflow.current.ID}`">{{ $t("Go to workflow") }} <i class="bi bi-box-arrow-up-right"></i></a></small>
                    </div>
                </div>
            </div>
        </div>
    </ODialog>
</template>

<script setup>
import { ref, defineProps } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';
import { vPopover } from 'o365-vue-utils';
import { ODialog, OTextArea } from 'o365-ui-components';
import { $t } from 'o365-utils';
import { getOrCreateProcedure } from 'o365-modules';
import { OAttachments, OFileUploadButton, OUploadDrop } from 'o365-fileupload';

const dialogRef = ref();
const targetEl = ref();
const showDialog = ref(false);
const submitting = ref(false);
const submitted = ref(true);
const isPopoverVisible = ref(false);

const props = defineProps({
    serverData: Object
})

var popoverRef = null;

const popoverSettings = {
    popoverRef: popover => popoverRef = popover,
    settings: {
        placement: "bottom",
        html: true,
        trigger: "focus",
        sanitize: false,
        selector: "[rel=popover]",
        content: getHelpPagesMarkup
    }
}
const clickOutsideListener = (event) => {
    if (popoverRef && !popoverRef._element.contains(event.target)) {
        if (isPopoverVisible.value) {
            isPopoverVisible.value = false;
            popoverRef.hide();
        }
        document.removeEventListener('click', clickOutsideListener);
    }
};

function beforeUpload(){
    { return { 'Item_ID': dsWorkflow.current.ID } }
}

const proceCreateEmptyProcess = getOrCreateProcedure({ id: "proceCreateEmptyProcess", procedureName: "astp_Scope_EmptyProcess"});
const procInitiateWorkflow = getOrCreateProcedure({ id: "procInitiate", procedureName: "astp_Scope_ItemInitiate" });

const dsProducts = getOrCreateDataObject({
    "id": `dsHelpPages-Navbar`,
    "isStaticId": true,
    "viewName": "sviw_O365_HelpPagesForNavbar",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "HostName",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "SortOrder",
            "sortOrder": 1,
            "sortDirection": "asc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "ArticleID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "URL",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "ShowInDialog",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "DisableFocus",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "HideSubmit",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "ModalSize",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "NameLocalized",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Name",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false, 
    "maxRecords": 100,
    "dynamicLoading": false,
    "whereClause": `(ArticleID = '${props.serverData.articleId}' AND HostName = 'Apps') OR ArticleID IS NULL`, 
    "loadRecents": false,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
});

const dsWorkflow = getOrCreateDataObject({
    "id": `dsWorkflow-Navbar`,
    "isStaticId": true,
    "viewName": "aviw_Scope_ItemsForRegistration",
    "distinctRows": false,
    "uniqueTable": "aviw_Scope_ItemsForRegistrationUniqueTable",
    "allowUpdate": true,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Title",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Description",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Process_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "OrgUnit",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "OrgUnit_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": 1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
});

const dsAttachments = getOrCreateDataObject({
    "id": "dsAttachments",
    "viewName": "aviw_Scope_ItemsFiles",
    "distinctRows": false,
    "uniqueTable": "atbv_Scope_ItemsFiles",
    "allowUpdate": true,
    "allowInsert": true,
    "allowDelete": true,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "FileName",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "PrimKey",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "FileSize",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "FileRef",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Extension",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Created",
            "sortOrder": 1,
            "sortDirection": "asc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Updated",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "CreatedBy",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "FileUpdated",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Item_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": -1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
})

async function submitWorkflow(){
    submitting.value = true;
    await dsWorkflow.save();
    await procInitiateWorkflow.execute({
        ID: dsWorkflow.current.ID
    });
    submitting.value = false;
    submitted.value = true;
} 

function getHelpPagesMarkup() {
    const translations = { GeneralHelp: $t("General help") };
    const generalHelp = `<div class="p-2 d-block"><a href="${props.serverData.helpUrl}" class="d-block ${dsProducts.data.length > 1 ? 'border-bottom border-muted' : ''}" target="_blank" rel="noopener noreferrer">${translations.GeneralHelp}</a>`;
    const pageHelp = dsProducts.data.map(item => {
        var parameters = processUrl(item.URL);
        if(parameters != null){
            if(item.Name){
                return `<a data-process-id="${parameters.processId}" data-org-unit-id="${parameters.orgUnitId}" class="mt-1 af-navbar-helppage-btn d-block ${item.ShowInDialog ? 'tb-use-dialog' : ''} ${item.DisableFocus ? 'no-focus' : ''} ${item.HideSubmit ? 'no-sub' : ''} ${item.ModalSize ? item.ModalSize : ''}" style="cursor:pointer">${item.NameLocalized}</button>`
            }
            return `<a data-process-id="${parameters.processId}" data-org-unit-id="${parameters.orgUnitId}" class="mt-1 d-block af-navbar-helppage-btn ${item.ShowInDialog ? 'tb-use-dialog' : ''} ${item.DisableFocus ? 'no-focus' : ''} ${item.HideSubmit ? 'no-sub' : ''} ${item.ModalSize ? item.ModalSize : ''}" style="cursor:pointer">${item.URL}</button>`
        } else {
            if (item.Name) {
                return `<a href="${item.URL}" class="mt-1 d-block ${item.ShowInDialog ? 'tb-use-dialog' : ''} ${item.DisableFocus ? 'no-focus' : ''} ${item.HideSubmit ? 'no-sub' : ''} ${item.ModalSize ? item.ModalSize : ''}" target="_blank" rel="noopener noreferrer">${item.NameLocalized}</a>`;
            }
            return `<a href="${item.URL}" class="mt-1 d-block ${item.ShowInDialog ? 'tb-use-dialog' : ''} ${item.DisableFocus ? 'no-focus' : ''} ${item.HideSubmit ? 'no-sub' : ''} ${item.ModalSize ? item.ModalSize : ''}" target="_blank" rel="noopener noreferrer">${item.URL}</a>`;
        }
    }).join("");
    return generalHelp + pageHelp + "</div>";
}

async function afNavbarShowWorkflowDialog(processId, orgUnitId){
    popoverRef.hide();
    isPopoverVisible.value = false;
    dialogRef.value.showDialog();
    var result = await proceCreateEmptyProcess.execute({
        OrgUnit_ID: orgUnitId,
        Process_ID: processId
    });
    var id = result.Table[0].ID;
    dsWorkflow.recordSource.whereClause = `ID = ${id}`;
    dsAttachments.recordSource.whereClause = `Item_ID = ${id}`;
    dsWorkflow.load();
    dsAttachments.load();
}

window["afNavbarShowWorkflowDialog"] = afNavbarShowWorkflowDialog;

function processUrl(u){
    try {
        const url = new URL(u);
        const params = new URLSearchParams(url.search);

        let processId = params.get('Process');
        let orgUnitId = params.get('Context');

        if(processId == null || orgUnitId == null){
            return null;
        }

        return {
            processId,
            orgUnitId
        }; 
    } catch(ex) {
        return null;                        
    }
}

async function show(){
    if(isPopoverVisible.value) {
        isPopoverVisible.value = false;
        popoverRef.hide();
        document.removeEventListener('click', clickOutsideListener);
        return;
    }
    if(!isPopoverVisible.value){
        await dsProducts.load();
        popoverRef.show();
        setTimeout(function(){
            const buttons = document.querySelectorAll('.af-navbar-helppage-btn');
            buttons.forEach(button => {
                button.addEventListener('click', () => {
                    var processId = button.dataset.processId;
                    var orgUnitId = button.dataset.orgUnitId;
                    afNavbarShowWorkflowDialog(+processId, +orgUnitId);
                });
            });
        }, 100)
        isPopoverVisible.value = true;
        document.addEventListener('click', clickOutsideListener);
    }
    submitted.value = false;
}

</script>